import * as Turbo from '@hotwired/turbo';
Turbo.session.drive = true;
import { clearAjaxModal } from './ajaxModal.js';

const visitHelper = fn => (...args) => {
    var result = fn.apply(null, args);
    clearAjaxModal();
    return result;
};

export const clearNavigationCache = () => {
    Turbo.clearCache();
};

export const redirectTo = visitHelper(url =>
    Turbo.visit(url, { action: 'replace' }),
);

export const goToUrl = visitHelper(Turbo.visit);
