import $ from 'jquery';

const getElements = (element) => {
    const jqElement = $(element);
    const textElement =
        jqElement.find('.load-feedback-text').length > 0
            ? jqElement.find('.load-feedback-text')
            : jqElement;
    return [jqElement, textElement];
};

export const startLoadingFeedback = (element) => {
    const [jqElement, textElement] = getElements(element);

    jqElement.addClass('disabled clicked');
    jqElement.is('button') && jqElement.attr('disabled', true);

    const feedbackText = textElement.attr('data-load-feedback-text');
    if (feedbackText === undefined) {
        return;
    }

    textElement.html(feedbackText);
};

export const stopLoadingFeedback = (element) => {
    const [jqElement, textElement] = getElements(element);

    jqElement.removeClass('disabled clicked');
    jqElement.is('button') && jqElement.removeAttr('disabled');

    const feedbackText = textElement.attr('data-load-feedback-text');
    if (feedbackText === undefined) {
        return;
    }

    textElement.html(jqElement.attr('data-default-load-feedback-text'));
};

export const toggleLoadingFeedback = (element, enable) => {
    const jqElement = $(element);
    if (jqElement.is('.disabled') && jqElement.is('.clicked') == false) {
        return;
    }

    enable || jqElement.is('.disabled.clicked')
        ? stopLoadingFeedback(jqElement)
        : startLoadingFeedback(jqElement);
};

export const init = (element) => {
    element = $(element || document);
    element
        .find(
            '.load-feedback:not([data-loaded]):not([data-default-load-feedback-text])',
        )
        .each((_, el) => {
            const [jqElement, textElement] = getElements(el);
            jqElement.attr(
                'data-default-load-feedback-text',
                textElement.text(),
            );
        });
    element
        .find('.load-feedback:not([type=submit]):not([data-loaded])')
        .attr('data-loaded', true)
        .click(({ currentTarget }) => toggleLoadingFeedback(currentTarget));

    element
        .find('[type=submit].load-feedback:not([data-loaded])')
        .attr('data-loaded', true)
        .parents('form:first')
        .submit(function () {
            const element = $(this).find('[type=submit]:first');
            if (element.is('.disabled') == false) {
                toggleLoadingFeedback(element);
            }
        });
};
