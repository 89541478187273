export const addMessage = (messages, type) => {
    type = type || 'warning';
    messages = !Array.isArray(messages) ? [messages] : messages;
    $('#messages-bag').append(`
        <div class="errors alert alert-${type} alert-dismissable">
            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
            <ul>
                ${messages
                    .map((message) => `<li class="nl2br">${message}</li>`)
                    .join('')}
            </ul>
        </div>
    `);
    $('html, body').animate(
        {
            scrollTop: $('#messages-bag').offset().top,
        },
        2000,
    );
};

export const clearMessages = () => {
    const el = $('#messages-bag .alert:not(.fixed-message)').slideUp({
        complete: () => el.remove(),
    });
};
