import $ from 'jquery';

export const applyMask = (element) => {
    const $element = $(element || document.body);

    $element.find(":input[mask='moeda']").inputmask({
        showMaskOnHover: false,
        clearIncomplete: true,
        rightAlign: false,
        greedy: false,
        alias: 'decimal',
        groupSeparator: '.',
        autoGroup: true,
        digits: 2,
        radixPoint: ',',
    });

    $element.find(':input[mask-money]').maskMoney();

    $element.find(':input[mask="cpfCnpj"]').inputmask({
        mask: ['999.999.999-99', '99.999.999/9999-99'],
        keepStatic: true,
        skipOptionalPartCharacter: '.-/',
        clearIncomplete: true,
    });

    $element.find(":input[mask='telefone']").inputmask({
        mask: ['(99) 9999-9999', '(99) 99999-9999'],
        showMaskOnHover: false,
        clearIncomplete: true,
        rightAlign: false,
        greedy: false,
        alias: 'telefone',
    });

    $element.find(":input[mask='telefone-9']").inputmask({
        mask: ['(99) 99999-9999'],
        showMaskOnHover: false,
        clearIncomplete: true,
        rightAlign: false,
        greedy: false,
        alias: 'telefone-9',
    });

    $element.find(":input[mask='telefone-ddd']").inputmask('99', {
        showMaskOnHover: false,
        clearIncomplete: true,
        rightAlign: false,
        greedy: false,
        alias: 'telefone-ddd',
    });

    $element.find(":input[mask='telefone-simples']").inputmask('9999[9]-9999', {
        showMaskOnHover: false,
        clearIncomplete: true,
        rightAlign: false,
        greedy: false,
        alias: 'telefone-simples',
    });

    $element.find(":input[mask='email']").inputmask({
        showMaskOnHover: false,
        clearIncomplete: true,
        rightAlign: false,
        greedy: false,
        alias: 'email',
    });

    $element.find('.cliente-telefone-control').map((_, element) => {
        const $element = $(element);
        $element.find('.telefone-input').on('input', function () {
            const value = this.value.replace(/[^0-9]/g, '');
            const ddd = value.substr(0, 2);
            const telefone = value.substr(2);

            $element.find('.telefone-input-ddd').val(ddd);
            $element.find('.telefone-input-numero').val(telefone);
        });
    });

    createDateMask($element, ":input[mask='date']", 'dd/mm/yyyy');
    createDateMask($element, ":input[mask='month']", 'mm/yyyy', 'months');
};

const createDateMask = ($element, selector, format, view = 'days') =>
    $element
        .find(selector)
        .inputmask(format.replace(/[^/]/g, '9'), {
            showMaskOnHover: false,
            clearIncomplete: true,
            rightAlign: false,
            greedy: false,
            alias: 'datetime',
            inputFormat: format,
        })
        .datepicker({
            format,
            language: 'pt-BR',
            orientation: 'bottom auto',
            todayBtn: view == 'days' ? 'linked' : false,
            autoclose: true,
            startView: view,
            minViewMode: view,
        });

export const unmaskMoney = (input) => {
    const $input = $(input);
    const value = parseFloat(
        $input
            .val()
            .replace(/[^0-9,]/, '')
            .replace(',', '.'),
    );

    $input.maskMoney('mask', value);
    return value;
};

const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

export const formatCurrency = (number) => formatter.format(number);
