let pusher = null;

export const setup = (
    pusherKey,
    pusherCluster,
    pusherEncrypted,
    log = false,
) => {
    console.debug('connectando ao pusher...');
    Pusher.logToConsole = log;
    return (pusher = new Pusher(pusherKey, {
        cluster: pusherCluster,
        forceTLS: pusherEncrypted,
    }));
};

export const waitEventToRefresh = (pusher, id, eventChannel, eventName) => {

    const channel = pusher.subscribe(eventChannel);

    const handle = (data) => (data.id == id) && window.location.reload();
    channel.bind(eventName, handle);

    $(document).on('turbolinks:before-cache', () => channel.unbind(eventName, handle))
}

export const getPusher = () => pusher;
