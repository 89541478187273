import $ from 'jquery';
import { showErrorDialogFromResponse } from './error.js';
import { applyMask } from './masks.js';
import { init as initToggleLoadingFeedback } from './toggle-loading-feedback.js';

let ajaxModalContent = null;
let ajaxModalIsOpen = false;
let lastHttpRequest = null;
let turboIsLoading = false;

export const requestAjaxModal = async (url) => {
    if (turboIsLoading) return;

    fillAjaxModal('<div/>');

    try {
        lastHttpRequest = $.ajax({
            method: 'GET',
            url,
            headers: {
                'x-error-accept': 'application/json',
            },
        });
        const data = await lastHttpRequest;
        lastHttpRequest = null;

        ajaxModalContent = $(data);
        ajaxModalIsOpen = true;
        fillAjaxModal(ajaxModalContent);
    } catch (error) {
        if (error.statusText === 'abort') {
            return;
        }

        getModal().modal('hide');
        showErrorDialogFromResponse(error);
    }
};

const getModal = () => {
    let modal = $('#ajaxModal');
    if (modal.length > 0) {
        return modal;
    }

    modal = $('<div class="modal" id="ajaxModal"></div>');
    $('#remote-modals').empty().append(modal);
    return modal;
};

const showAjaxModal = () => {
    const modal = getModal();

    $('.modal-backdrop.in').remove();
    modal.modal({
        backdrop: 'static',
        keyboard: true,
    });
    ajaxModalIsOpen = true;
    modal.modal('show');
    modal.on('hidden.bs.modal', () => $('body').css('padding-right', '0'));
};

const fillAjaxModal = (content) => {
    getModal().replaceWith($(content));

    const modal = getModal();

    applyMask(modal);
    initToggleLoadingFeedback(modal);
    showAjaxModal();

    $(modal).on('hide.bs.modal', () => {
        modal.html('');
        clearAjaxModal();
        $('.modal-backdrop.in').remove();
    });
};

export const clearAjaxModal = () => {
    ajaxModalIsOpen = false;
    ajaxModalContent = null;
};

export const init = () => {
    const render = () => {
        if (ajaxModalIsOpen) {
            showAjaxModal();
        }

        if (ajaxModalContent != null) {
            fillAjaxModal($(ajaxModalContent));
        }
    };

    $(document)
        .on('turbo:visit', () => {
            turboIsLoading = true;
            lastHttpRequest && lastHttpRequest.abort && lastHttpRequest.abort();
            clearAjaxModal();
        })
        .on('turbo:load', () => {
            turboIsLoading = false;
            $('button[data-toggle="ajaxModal"]:not([data-loaded])')
                .attr('data-loaded', true)
                .on('click', function (event) {
                    event.preventDefault();

                    $('.modal-backdrop').remove();

                    requestAjaxModal($(this).data('remote'));
                });
        })
        .on('turbo:load', render)
        .on('turbo:render', render)
        .on('turbo:before-cache', () => $('.modal').modal('hide'));
};
