import $ from 'jquery';
import { addMessage } from './messages';

export const showError = (errorMessages) => addMessage(errorMessages, 'danger');

export const clearErrorMessages = () => {
    var el = $('#messages-bag .alert-danger:not(.fixed-message)').slideUp({
        complete: () => el.remove(),
    });
};

export const processErrorResponse = (
    error,
    defaultMessage = 'Ocorreu um erro desconhecido nessa operação, favor tentar novamente mais tarde',
) =>
    new Promise((fullfill) => {
        if (error.statusText == 'abort') {
            return;
        }

        var json = error.responseJSON;
        if (typeof json === 'undefined' || typeof json.error === 'undefined') {
            console.error(error);
            return fullfill({
                status: 400,
                error: defaultMessage,
            });
        }

        fullfill({
            status: json.status || 400,
            error: json.error || defaultMessage,
            reload: json.redirectToLogin || false,
        });
    });

export const showErrorFromResponse = (error) =>
    processErrorResponse(error).then(({ error, reload }) => {
        reload && window.location.reload();
        showError(error);
    });

export const showErrorDialogFromResponse = (error) =>
    processErrorResponse(error).then(({ error, reload }) =>
        showErrorDialog(error, reload),
    );

export const showErrorDialog = (error, reload = false) => {
    const errorModal = $('#alertModalError');
    const otherModals = $('.modal:not(#alertModalError)');
    errorModal.on('hidden.bs.modal', () => {
        otherModals.removeClass('ofuscated');

        if (reload) {
            window.location.reload();
        }
    });

    errorModal.find('.modal-body').html(error);

    otherModals.addClass('ofuscated');
    errorModal.modal('show');
};
